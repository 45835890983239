/* eslint-disable */

// This is a generated file and SHOULD NOT BE EDITED MANUALLY!!
// Contents are generated as part of grunt build
// Source of truth is lib/cdo/shared_constants.rb and files in lib/cdo/shared_constants/

export const ApplabBlocks = {
  "onEvent": null,
  "button": null,
  "textInput": null,
  "textLabel": null,
  "dropdown": null,
  "getText": null,
  "setText": null,
  "getNumber": null,
  "setNumber": null,
  "checkbox": null,
  "radioButton": null,
  "getChecked": null,
  "setChecked": null,
  "image": null,
  "getImageURL": null,
  "setImageURL": null,
  "playSound": null,
  "stopSound": null,
  "playSpeech": null,
  "showElement": null,
  "hideElement": null,
  "deleteElement": null,
  "setPosition": null,
  "setSize": null,
  "setProperty": null,
  "getProperty": null,
  "write": null,
  "getXPosition": null,
  "getYPosition": null,
  "setScreen": null,
  "rgb": null,
  "open": null,
  "createCanvas": null,
  "setActiveCanvas": null,
  "line": null,
  "circle": null,
  "rect": null,
  "setStrokeWidth": null,
  "setStrokeColor": null,
  "setFillColor": null,
  "drawImageURL": null,
  "getImageData": null,
  "putImageData": null,
  "clearCanvas": null,
  "getRed": null,
  "getGreen": null,
  "getBlue": null,
  "getAlpha": null,
  "setRed": null,
  "setGreen": null,
  "setBlue": null,
  "setAlpha": null,
  "setRGB": null,
  "getColumn": null,
  "startWebRequest": null,
  "setKeyValue": null,
  "getKeyValue": null,
  "createRecord": null,
  "readRecords": null,
  "updateRecord": null,
  "deleteRecord": null,
  "getUserId": null,
  "drawChart": null,
  "drawChartFromRecords": null,
  "getPrediction": null,
  "moveForward": null,
  "moveBackward": null,
  "move": null,
  "moveTo": null,
  "dot": null,
  "turnRight": null,
  "turnLeft": null,
  "turnTo": null,
  "arcRight": null,
  "arcLeft": null,
  "getX": null,
  "getY": null,
  "getDirection": null,
  "penUp": null,
  "penDown": null,
  "penWidth": null,
  "penColor": null,
  "penRGB": null,
  "show": null,
  "hide": null,
  "speed": null,
  "forLoop_i_0_4": null,
  "ifBlock": null,
  "ifElseBlock": null,
  "whileBlock": null,
  "setTimeout": null,
  "clearTimeout": null,
  "timedLoop": null,
  "stopTimedLoop": null,
  "getTime": null,
  "addOperator": null,
  "subtractOperator": null,
  "multiplyOperator": null,
  "divideOperator": null,
  "moduloOperator": null,
  "equalityOperator": null,
  "inequalityOperator": null,
  "greaterThanOperator": null,
  "greaterThanOrEqualOperator": null,
  "lessThanOperator": null,
  "lessThanOrEqualOperator": null,
  "andOperator": null,
  "orOperator": null,
  "notOperator": null,
  "randomNumber_min_max": null,
  "mathRound": null,
  "mathAbs": null,
  "mathMax": null,
  "mathMin": null,
  "mathRandom": null,
  "mathPow": null,
  "mathSqrt": null,
  "declareAssign_x": null,
  "declareNoAssign_x": null,
  "assign_x": null,
  "declareAssign_x_prompt": null,
  "declareAssign_x_promptNum": null,
  "console.log": null,
  "console.clear": null,
  "declareAssign_str_hello_world": null,
  "substring": null,
  "indexOf": null,
  "includes": null,
  "length": null,
  "toUpperCase": null,
  "toLowerCase": null,
  "declareAssign_list_abd": null,
  "declareAssign_list_123": null,
  "accessListItem": null,
  "listLength": null,
  "insertItem": null,
  "appendItem": null,
  "removeItem": null,
  "join": null,
  "declareAssign_object": null,
  "getValue": null,
  "addPair": null,
  "functionParams_none": null,
  "functionParams_n": null,
  "callMyFunction": null,
  "callMyFunction_n": null,
  "return": null,
  "comment": null,
  "pinMode": null,
  "digitalWrite": null,
  "digitalRead": null,
  "analogWrite": null,
  "analogRead": null,
  "boardConnected": null,
  "var myLed = createLed": null,
  "var myButton = createButton": null,
  "var mySensor = createCapacitiveTouchSensor": null,
  "__.on": null,
  "__.off": null,
  "__.toggle": null,
  "__.blink": null,
  "__.pulse": null,
  "colorLeds[0].on": null,
  "colorLeds[0].off": null,
  "colorLeds[0].toggle": null,
  "colorLeds[0].blink": null,
  "colorLeds[0].pulse": null,
  "stop": null,
  "color": null,
  "intensity": null,
  "led.on": null,
  "led.off": null,
  "led.blink": null,
  "led.toggle": null,
  "led.pulse": null,
  "buzzer.frequency": null,
  "buzzer.note": null,
  "buzzer.stop": null,
  "buzzer.playNotes": null,
  "buzzer.playSong": null,
  "accelerometer.getOrientation": null,
  "accelerometer.getAcceleration": null,
  "isPressed": null,
  "holdtime": null,
  "soundSensor.value": null,
  "soundSensor.setScale": null,
  "soundSensor.threshold": null,
  "lightSensor.value": null,
  "lightSensor.setScale": null,
  "lightSensor.threshold": null,
  "tempSensor.F": null,
  "tempSensor.C": null,
  "toggleSwitch.isOpen": null,
  "onBoardEvent": null,
  "ledScreen.on": null,
  "ledScreen.off": null,
  "ledScreen.toggle": null,
  "ledScreen.display": null,
  "ledScreen.clear": null,
  "ledScreen.scrollNumber": null,
  "ledScreen.scrollString": null,
  "compass.getHeading": null
};
