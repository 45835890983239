// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uaLW1kyMjsFGurhbdXyY{background-color:#0aa;color:#fff;float:right}", "",{"version":3,"sources":["webpack://./src/applab/designElements/restore-theme-defaults-button.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".restoreButton {\n  background-color: #0aa;\n  color: white;\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"restoreButton": "uaLW1kyMjsFGurhbdXyY"
};
export default ___CSS_LOADER_EXPORT___;
