module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    "use strict";
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = "<%\n/**\n * This block destructures the local variables for use within this ejs template.\n */\nvar appName = locals.appName;\n%>\n# README for <%- appName %> #\n\nThis is the README file for the <%- appName %> app. It's a great place to write\ndown some notes about how your app works. For starters, here is some information\nabout the other files in this folder.\n\nThe files in this folder contain everything you need to run your app:\n\n* `applab/applab.js` - This file contains the applab library, which has all the\n  javascript that applab uses to run your app.\n\n* `applab/applab.css` - This file contains some default CSS styling for your app.\n\n* `index.html` - This is the html file that you designed in applab using the\n  Design tab. It contains all the buttons, text inputs, and other controls for\n  your app. Go here to add additional controls to your app.\n\n* `style.css` - This file defines the custom styling for each control in your\n  app that you specified under the Design tab. Go here to change the colors,\n  positions, dimensions, and other properties of your app's controls.\n\n* `code.js` - This file has all the code you wrote for your app. Go here to add\n  new behavior to your app.\n\n* `assets` - This is a directory with all the asset files (pictures and sounds)\n  that you uploaded in applab.\n", __filename = "src/templates/export/projectReadme.md.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        var appName = locals.appName;
        __line = 6;
        __append("\n# README for ");
        __line = 7;
        __append(appName);
        __append(" #\n\nThis is the README file for the ");
        __line = 9;
        __append(appName);
        __append(" app. It's a great place to write\ndown some notes about how your app works. For starters, here is some information\nabout the other files in this folder.\n\nThe files in this folder contain everything you need to run your app:\n\n* `applab/applab.js` - This file contains the applab library, which has all the\n  javascript that applab uses to run your app.\n\n* `applab/applab.css` - This file contains some default CSS styling for your app.\n\n* `index.html` - This is the html file that you designed in applab using the\n  Design tab. It contains all the buttons, text inputs, and other controls for\n  your app. Go here to add additional controls to your app.\n\n* `style.css` - This file defines the custom styling for each control in your\n  app that you specified under the Design tab. Go here to change the colors,\n  positions, dimensions, and other properties of your app's controls.\n\n* `code.js` - This file has all the code you wrote for your app. Go here to add\n  new behavior to your app.\n\n* `assets` - This is a directory with all the asset files (pictures and sounds)\n  that you uploaded in applab.\n");
        __line = 33;
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}