// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tAnb0bBXCUZvyBrrkXuw{display:inline-block;max-width:350px;overflow:hidden;white-space:nowrap}.OrsXFecTOreX5aQL7I3j{justify-content:space-between;padding:6px 0;display:flex}.dkadH113fyqnjTBszRya{display:none}.gaA0IZHi8gZ4H179jcG9{align-self:flex-end;padding-left:5px}.fVuhMIjgfjTObI7CnihS{color:#fff;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/storage/dataBrowser/column-header.module.scss"],"names":[],"mappings":"AAEA,sBACE,oBAAA,CACA,eAJe,CAKf,eAAA,CACA,kBAAA,CAGF,sBACE,6BAAA,CACA,aAAA,CACA,YAAA,CACA,sBACE,YAAA,CAIJ,sBACE,mBAAA,CACA,gBAAA,CAGF,sBACE,UAAA,CACA,cAAA","sourcesContent":["$max-cell-width: 350px;\n\n.columnName {\n  display: inline-block;\n  max-width: $max-cell-width;\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n.container {\n  justify-content: space-between;\n  padding: 6px 0;\n  display: flex;\n  &IsEditing {\n    display: none;\n  }\n}\n\n.iconWrapper {\n  align-self: flex-end;\n  padding-left: 5px;\n}\n\n.icon {\n  color: white;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columnName": "tAnb0bBXCUZvyBrrkXuw",
	"container": "OrsXFecTOreX5aQL7I3j",
	"containerIsEditing": "dkadH113fyqnjTBszRya",
	"iconWrapper": "gaA0IZHi8gZ4H179jcG9",
	"icon": "fVuhMIjgfjTObI7CnihS"
};
export default ___CSS_LOADER_EXPORT___;
