// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JUz7bvMsCD64YzQs9JJX{display:inline-block;margin-bottom:10px;margin-top:10px}.YKKOcV8d6S0FjvBjErLy{content:\"\";display:inline-block;width:100%;height:0;font-size:0;line-height:0px}.OuOFoVqjBWXFQ_X31Ns7{margin-bottom:-28px;margin-top:-10px;padding-top:0;padding-bottom:10px;padding-left:0;padding-right:0;text-align:justify}.LHtWhw3Ds79AVCaklnWh{width:120px;margin-left:10px}.l0ad064ZGJAGAfKDRKfl{margin-left:0}.qVOvxyVKD_q5Q2hZY46t{font-size:18px}.Y2P6Nqd2Qw5kBTQ5SKz_{align-items:flex-end;display:inline-flex;height:30px;margin-right:10px;vertical-align:middle}", "",{"version":3,"sources":["webpack://./src/storage/dataBrowser/table-controls.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,kBAAA,CACA,eAAA,CAGF,sBACE,UAAA,CACA,oBAAA,CACA,UAAA,CACA,QAAA,CACA,WAAA,CACA,eAAA,CAGF,sBAEE,mBAAA,CAEA,gBAAA,CACA,aAAA,CACA,mBAAA,CACA,cAAA,CACA,eAAA,CAGA,kBAAA,CAGF,sBACE,WAAA,CACA,gBAAA,CAGF,sBACE,aAAA,CAGF,sBACE,cAAA,CAGF,sBACE,oBAAA,CACA,mBAAA,CACA,WAAA,CACA,iBAAA,CACA,qBAAA","sourcesContent":[".buttonWrapper {\n  display: inline-block;\n  margin-bottom: 10px;\n  margin-top: 10px;\n}\n\n.clearfix {\n  content: \"\";\n  display: inline-block;\n  width: 100%;\n  height: 0;\n  font-size: 0;\n  line-height: 0px;\n}\n\n.container {\n  // subtract the height of the clearfix element\n  margin-bottom: -28px;\n  // subtract the top margin of the buttonWrapper\n  margin-top: -10px;\n  padding-top: 0;\n  padding-bottom: 10px;\n  padding-left: 0;\n  padding-right: 0;\n  // make the buttons align right usually, but align left if they\n  // are forced to wrap onto the next line by a very long table name.\n  text-align: justify;\n}\n\n.exportButton {\n  width: 120px;\n  margin-left: 10px;\n}\n\n.exportButtonRtl {\n  margin-left: 0;\n}\n\n.tableName {\n  font-size: 18px;\n}\n\n.tableNameWrapper {\n  align-items: flex-end;\n  display: inline-flex;\n  height: 30px;\n  margin-right: 10px;\n  vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrapper": "JUz7bvMsCD64YzQs9JJX",
	"clearfix": "YKKOcV8d6S0FjvBjErLy",
	"container": "OuOFoVqjBWXFQ_X31Ns7",
	"exportButton": "LHtWhw3Ds79AVCaklnWh",
	"exportButtonRtl": "l0ad064ZGJAGAfKDRKfl",
	"tableName": "qVOvxyVKD_q5Q2hZY46t",
	"tableNameWrapper": "Y2P6Nqd2Qw5kBTQ5SKz_"
};
export default ___CSS_LOADER_EXPORT___;
