// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zNECB_z65Uj0WdXLqKpM{background-color:#0aa;color:#fff;float:right}.oduIyx6FqKVhXaBtgkie{max-height:200px;overflow-y:auto}", "",{"version":3,"sources":["webpack://./src/applab/designElements/copy-element-to-screen-button.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,UAAA,CACA,WAAA,CAGF,sBACE,gBAAA,CACA,eAAA","sourcesContent":[".copyElementToScreenButton {\n  background-color: #0aa;\n  color: white;\n  float: right;\n}\n\n.menu {\n  max-height: 200px;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyElementToScreenButton": "zNECB_z65Uj0WdXLqKpM",
	"menu": "oduIyx6FqKVhXaBtgkie"
};
export default ___CSS_LOADER_EXPORT___;
