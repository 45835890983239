/* eslint-disable */

// This is a generated file and SHOULD NOT BE EDITED MANUALLY!!
// Contents are generated as part of grunt build
// Source of truth is lib/cdo/shared_constants.rb and files in lib/cdo/shared_constants/

export const ApplabGoalBlocks = {
  "comment_Goals_1": null,
  "comment_Goals_2": null,
  "comment_Goals_3": null,
  "comment_Goals_4": null,
  "comment_Goals_5": null,
  "comment_Goals_6": null,
  "comment_Goals_7": null,
  "comment_Goals_8": null,
  "comment_Goals_9": null,
  "comment_Goals_10": null,
  "comment_Goals_11": null,
  "comment_Goals_12": null,
  "comment_Goals_13": null,
  "comment_Goals_14": null,
  "comment_Goals_15": null,
  "comment_Goals_16": null,
  "comment_Goals_17": null,
  "comment_Goals_18": null,
  "comment_Goals_19": null,
  "comment_Goals_20": null
};
