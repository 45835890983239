module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    "use strict";
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<%\n/**\n * This block destructures the local variables for use within this ejs template.\n */\nvar appName = locals.appName;\nvar exportConfigPath = locals.exportConfigPath;\nvar htmlBody = locals.htmlBody;\nvar faBrandsPath = locals.faBrandsPath;\nvar faSolidPath = locals.faSolidPath;\nvar faRegularPath = locals.faRegularPath;\nvar faV4CompatibilityPath = locals.faV4CompatibilityPath;\n%>\n<html>\n  <head>\n    <title><%- appName %></title>\n    <meta charset="utf-8" />\n    <meta name="viewport" content="width=device-width, initial-scale=1">\n    <link rel="preload" href="<%- faBrandsPath %>" as="font">\n    <link rel="preload" href="<%- faSolidPath %>" as="font">\n    <link rel="preload" href="<%- faRegularPath %>" as="font">\n    <link rel="preload" href="<%- faV4CompatibilityPath %>" as="font">\n    <script src="https://code.jquery.com/jquery-1.12.1.min.js"><\/script>\n    <script>\n      function setExportConfig(config) { window.EXPORT_OPTIONS = config; }\n    <\/script>\n    <script src="<%- exportConfigPath %>"><\/script>\n    <script src="applab/applab-api.js"><\/script>\n    <script src="https://www.google.com/jsapi"><\/script>\n    <link rel="stylesheet" href="applab/applab.css">\n    <link rel="stylesheet" href="style.css">\n  </head>\n  <body>\n    <%- htmlBody %>\n  </body>\n</html>\n', __filename = "src/templates/export/project.html.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        var appName = locals.appName;
        var exportConfigPath = locals.exportConfigPath;
        var htmlBody = locals.htmlBody;
        var faBrandsPath = locals.faBrandsPath;
        var faSolidPath = locals.faSolidPath;
        var faRegularPath = locals.faRegularPath;
        var faV4CompatibilityPath = locals.faV4CompatibilityPath;
        __line = 12;
        __append("\n<html>\n  <head>\n    <title>");
        __line = 15;
        __append(appName);
        __append('</title>\n    <meta charset="utf-8" />\n    <meta name="viewport" content="width=device-width, initial-scale=1">\n    <link rel="preload" href="');
        __line = 18;
        __append(faBrandsPath);
        __append('" as="font">\n    <link rel="preload" href="');
        __line = 19;
        __append(faSolidPath);
        __append('" as="font">\n    <link rel="preload" href="');
        __line = 20;
        __append(faRegularPath);
        __append('" as="font">\n    <link rel="preload" href="');
        __line = 21;
        __append(faV4CompatibilityPath);
        __append('" as="font">\n    <script src="https://code.jquery.com/jquery-1.12.1.min.js"><\/script>\n    <script>\n      function setExportConfig(config) { window.EXPORT_OPTIONS = config; }\n    <\/script>\n    <script src="');
        __line = 26;
        __append(exportConfigPath);
        __append('"><\/script>\n    <script src="applab/applab-api.js"><\/script>\n    <script src="https://www.google.com/jsapi"><\/script>\n    <link rel="stylesheet" href="applab/applab.css">\n    <link rel="stylesheet" href="style.css">\n  </head>\n  <body>\n    ');
        __line = 33;
        __append(htmlBody);
        __append("\n  </body>\n</html>\n");
        __line = 36;
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}